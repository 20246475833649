<template>
  <div v-if="$can('role_list', 'role')">
    <v-card>
      <v-form
        ref="searchForm"
        @submit.prevent="toSearch()"
      >
        <v-card-text class="d-flex flex-row-reverse">
          <div class="d-flex align-center pt-3">
            <v-btn
              class="me-3"
              @click="resetSearch()"
            >
              <span>重置</span>
            </v-btn>
          </div>
          <div class="d-flex align-center pt-3">
            <v-btn
              color="secondary"
              class="me-3"
              type="submit"
            >
              <v-icon
                size="18"
                class="me-1"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
              <span>查询</span>
            </v-btn>
          </div>

          <div class="d-flex align-center pt-3 mr-10">
            <label for="searchName"> <span class="text-subtitle-2">输入搜索：</span> </label>
            <v-text-field v-model="search.name"></v-text-field>
          </div>
        </v-card-text>
      </v-form>
    </v-card>

    <v-card class="mt-2">
      <v-card-title
        v-if="$can('role_save', 'role')"
        class="d-flex align-center flex-wrap pb-0"
      >
        <div class="d-flex align-center pb-5">
          <!-- create invoice -->
          <v-btn
            color="primary"
            class="me-3"
            @click="editItem()"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新增</span>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            itemsPerPageAllText: '全部',
            itemsPerPageText: '每页显示 ',
            showCurrentPage: true
          }"
          :page="pages.page"
          :items-per-page="pages.itemsPerPage"
          :server-items-length="pages.serverItemsLength"
          no-data-text="无数据"
          :headers="getHeader()"
          :items="roles"
          @pagination="loadPage"
        >
          <template
            #[`item.isMiniApp`]="{ item }"
          >
            <v-switch
              v-model="item.isMiniApp"
              dense
              :disabled="item.isSystem"
              x-small
              :label="item.isMiniApp ? `访问`: `禁止`"
              @change="toggleIsMiniApp(item)"
            ></v-switch>
          </template>
          <!-- action -->
          <template #[`item.actions`]="{ item }">
            <v-tooltip
              v-if="$can('role_user_list', 'role')"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="me-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="openUserDialog(item)"
                >
                  {{ icons.mdiAccount }}
                </v-icon>
              </template>
              <span>用户列表</span>
            </v-tooltip>
            <v-tooltip
              v-if="$can('role_menu_allocate', 'role')"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="me-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="openMenuDialog(item)"
                >
                  {{ icons.mdiMenu }}
                </v-icon>
              </template>
              <span>分配菜单</span>
            </v-tooltip>

            <v-tooltip
              v-if="$can('role_resource_allocate', 'role')"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="me-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="openResourceDialog(item)"
                >
                  {{ icons.mdiAlphaRBox }}
                </v-icon>
              </template>
              <span>分配资源</span>
            </v-tooltip>
            <v-tooltip
              v-if="$can('role_edit', 'role') && !item.isSystem"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  class="me-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                >
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </template>
              <span>编辑</span>
            </v-tooltip>
            <v-tooltip
              v-if="$can('role_delete', 'role') && !item.isSystem"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="openDeleteDialog(item)"
                >
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </template>
              <span>删除</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>

      <v-navigation-drawer
        v-model="editDialog"
        temporary
        touchless
        :right="!$vuetify.rtl"
        :width="$vuetify.breakpoint.smAndUp ? 750 : '100%'"
        app
      >
        <v-card height="100%">
          <div class="drawer-header d-flex align-center">
            <span class="font-weight-semibold text-base text-h6">{{ editedItem.id ? '编辑' : '添加' }}角色</span>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-5"
              color="primary"
              :disabled="commitFlag"
              :loading="commitFlag"
              @click="handleFormSubmit()"
            >
              保存
            </v-btn>
            <v-btn
              icon
              small
              @click="editDialog = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </div>

          <v-card-text
            style="background-color: #F4F5FA; height: 90%; padding-top: 20px;"
            class="overflow-auto"
          >
            <v-card>
              <v-form
                ref="editForm"
                lazy-validation
                @submit.prevent="handleFormSubmit"
              >
                <v-card-text class="pa-3">
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="name"><span style="color: red">*</span> 角色名称：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-text-field
                          id="name"
                          v-model="editedItem.name"
                          :rules="rules.required"
                          required
                          counter="25"
                          label="名称"
                          outlined
                          clearable
                          dense
                        ></v-text-field>
                      </v-col>
                      <!-- <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="code"><span style="color: red">*</span>角色编码：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-text-field
                          id="code"
                          v-model="editedItem.code"
                          required
                          :rules="rules.required"
                          label="编码"
                          outlined
                          clearable
                          dense
                        ></v-text-field>
                      </v-col> -->
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="description">角色描述：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-textarea
                          v-model="editedItem.description"
                          outlined
                          name="input-7-4"
                          label="描述"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-form>
            </v-card>
          </v-card-text>
        </v-card>
      </v-navigation-drawer>

      <v-dialog
        v-model="deleteDialog"
        width="300"
      >
        <v-card>
          <v-card-text> 是否删除 {{ this.deleteData.name }} ? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="deleteItem(id)"
            >
              确认删除
            </v-btn>
            <v-btn
              color="normal"
              text
              @click="deleteDialog = false"
            >
              取消
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <TreeSelect
        v-model="menuDialog"
        :active="active"
        :multiple="true"
        :selected-tree-data="selectedTreeData"
        title="分配菜单"
        @getActive="getActive"
      ></TreeSelect>

      <v-dialog
        v-model="resourceDialog"
        scrollable
        width="1000"
      >
        <v-card style="background-color: #f4f5fa ;">
          <v-card-title style="background-color: #ffffff;">
            <span class="text-h6">分配资源</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container
              v-for="item in resourcesData"
              v-if="item.resourceList"
              :key="item.id"
            >
              <v-card>
                <v-card-text>
                  <div style="height: 40px; ">
                    <v-checkbox
                      v-model="item.selected"
                      :label="item.name"
                      @change="changeChildStatus(item)"
                    ></v-checkbox>
                  </div>
                  <v-divider
                    v-if="item.resourceList"
                  ></v-divider>
                  <v-container row>
                    <v-row dense>
                      <v-col
                        v-for="child in item.resourceList"
                        :key="child.id"
                        cols="12"
                        style="height: 30px; "
                        md="3"
                      >
                        <v-checkbox
                          v-model="child.selected"
                          dense
                          :label="child.name"
                          @change="changeStatus(item)"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions style="background-color: #ffffff;">
            <v-spacer></v-spacer>
            <v-btn
              text
              color="secondary"
              @click="resourceDialog = false"
            >
              关闭
            </v-btn>

            <v-btn
              text
              color="error"
              @click="resetCheckbox()"
            >
              清空
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="updateRoleResoutceRlt()"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="userDialog"
        width="400"
      >
        <v-card>
          <v-card-title>用户列表</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :footer-props="{
                itemsPerPageAllText: '全部',
                itemsPerPageText: '每页显示 ',
                showCurrentPage: true
              }"
              :page="userPages.page"
              :items-per-page="userPages.itemsPerPage"
              :server-items-length="userPages.serverItemsLength"
              :headers="userHeaders"
              :items="users"
              no-data-text="无数据"
              @pagination="loadUserPage"
            >
            </v-data-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="normal"
              text
              @click="userDialog = false"
            >
              关闭
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiMagnify,
  mdiMenu,
  mdiAlphaRBox,
  mdiAccount,
} from '@mdi/js'
import ResourceApi from '@/api/system/resource/resource'
import RoleApi from '@/api/system/role.js'
import UserApi from '@/api/ucnter/user'
import TreeSelect from '@/components/select/TreeSelect'
import MenuApi from '@/api/system/menu.js'

export default {
  name: 'Role',
  components: {
    TreeSelect,
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiMagnify,
        mdiMenu,
        mdiAlphaRBox,
        mdiAccount,
      },
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
      },
      userPages: {
        itemsPerPage: 15,
        page: 1,
        serverItemsLength: 0,
      },
      userHeaders: [{ text: '用户名称', value: 'nickName', align: 'center' }],
      rules: {
        required: [v => !!v || '该字段不能为空'],
      },
      roles: [],
      users: [],
      editDialog: false,
      deleteDialog: false,
      menuDialog: false,
      resourceDialog: false,
      userDialog: false,
      loadings: {
        submitLoading: false,
      },
      selectedTreeData: [],
      resourcesData: [],
      deleteData: {},
      roleMenuRltData: {},
      roleResourceData: {},
      editedItem: {},
      defaultItem: {
        name: '',
        description: '',
        code: '',
      },
      search: {},
      currentRoleId: 0,
      active: [],
      commitFlag: false,
    }
  },
  mounted() {},
  methods: {
    getHeader() {
      const baseHeader = [
        { text: '编号', sortable: false, value: 'id' },
        { text: '角色名称', value: 'name' },
        { text: '描述', value: 'description' },
        { text: '添加时间', value: 'createdTime' },
        { text: '访问小程序', value: 'isMiniApp' },
      ]
      if (this.$can('role_edit', 'role')
          || this.$can('role_menu_allocate', 'role')
          || this.$can('role_resource_allocate', 'role')
          || this.$can('role_user_list', 'role')
          || this.$can('role_delete', 'role')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    editItem(item) {
      if (item) {
        this.editedItem = item
      } else {
        this.initEditedItem()
        this.$nextTick(() => {
          const { editForm } = this.$refs
          editForm.resetValidation()
        })
      }
      this.commitFlag = false
      this.editDialog = true
    },
    toggleIsMiniApp(item) {
      RoleApi.toggleIsMiniApp(item.id, item.isMiniApp).then(res => {
        this.$toast.success('修改成功！')
      })
    },
    deleteItem() {
      RoleApi.delete(this.deleteData.id).then(res => {
        this.$toast.success('删除成功！')
        this.deleteDialog = false
        this.loadPage(this.pages)
      })
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.editForm.validate()
      if (!isFormValid) return
      if (this.commitFlag) return
      this.commitFlag = true
      this.editedItem.code = 'user'
      RoleApi.save(this.editedItem).then(res => {
        this.$toast.success('保存成功')
        this.editDialog = false
        this.closeDialog()
        this.loadPage({ page: this.pages.page, itemsPerPage: this.pages.itemsPerPage })
      })
    },
    getActive(val) {
      const menuIdList = []
      if (val && val.length > 0) {
        val.forEach(item => {
          menuIdList.push(item.id)
        })
      }
      this.roleMenuRltData.menuIdList = menuIdList
      MenuApi.allocateRoleMenus(this.roleMenuRltData).then(res => {
        this.$toast.success('保存成功')
        this.menuDialog = false
      })
    },
    updateRoleResoutceRlt() {
      const resourceIdList = []
      if (this.resourcesData && this.resourcesData.length > 0) {
        this.resourcesData.forEach(father => {
          if (father.selected) {
            if (father.resourceList) {
              father.resourceList.forEach(item => {
                if (item.selected) {
                  resourceIdList.push(item.id)
                }
              })
            }
          }
        })
      }
      this.roleResourceData.resourceIdList = resourceIdList
      ResourceApi.allocateRoleResources(this.roleResourceData).then(res => {
        this.$toast.success('保存成功')
        this.resourceDialog = false
      })
    },
    closeDialog() {
      this.$refs.editForm.resetValidation()
      this.editedItem = this.defaultItem
      this.editDialog = false
    },
    openDeleteDialog(data) {
      this.deleteData = data
      this.deleteDialog = true
    },
    openMenuDialog(data) {
      this.roleMenuRltData = {}
      this.roleMenuRltData.roleId = data.id
      MenuApi.getRoleMenus(data.id).then(res => {
        const { data } = res.data
        this.selectedTreeData = data
        const active = []
        this.findActive(active, data)
        this.active = active
        this.menuDialog = true
      })
    },
    openResourceDialog(data) {
      this.roleResourceData = {}
      this.roleResourceData.roleId = data.id
      ResourceApi.getRoleResources(data.id).then(res => {
        const { data } = res.data
        this.resourcesData = data
      })
      this.resourceDialog = true
    },
    openUserDialog(data) {
      this.currentRoleId = data.id
      this.users = []
      this.userPages.page = 1
      this.loadUserPage({ page: 1, itemsPerPage: this.userPages.itemsPerPage })
    },
    findActive(active, data) {
      if (data.length == 0) {
        return
      }
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        if (item.selected) {
          active.push(item)
        }
        if (item.children && item.children.length > 0) {
          this.findActive(active, item.children)
        }
      }
    },
    loadPage({ page, itemsPerPage }) {
      const data = {
        rows: itemsPerPage,
        page,
        name: this.search.name,
      }
      RoleApi.getRolePages(data).then(res => {
        const { data } = res.data
        this.pages.page = data.current
        this.roles = data.records

        if (data.size === -1) {
          this.pages.serverItemsLength = this.roles.length
        } else {
          this.pages.serverItemsLength = data.total
        }
      })
    },
    loadUserPage({ page, itemsPerPage }) {
      if (this.currentRoleId == 0) {
        return
      }
      const data = {
        rows: itemsPerPage,
        page,
        id: this.currentRoleId,
      }
      UserApi.getUserPageByRole(data).then(res => {
        const { data } = res.data
        if (data) {
          this.users = data.records
          if (data.size === -1) {
            this.userPages.serverItemsLength = this.users.length
          } else {
            this.userPages.serverItemsLength = data.total
          }
        } else {
          this.userPages.serverItemsLength = 0
        }
        this.userDialog = true
      })
    },
    resetSearch() {
      this.$refs.searchForm.reset()
      this.toSearch()
    },
    toSearch() {
      this.loadPage({ page: 1, itemsPerPage: this.pages.itemsPerPage })
    },
    changeStatus(father) {
      father.selected = true
    },
    changeChildStatus(item) {
      if (item.resourceList) {
        item.resourceList.forEach(child => {
          child.selected = item.selected
        })
      }
    },
    resetCheckbox() {
      this.resourcesData.forEach(father => {
        father.selected = false
        this.changeChildStatus(father)
      })
    },
    initEditedItem() {
      this.defaultItem.id = ''
      this.defaultItem.code = ''
      this.defaultItem.name = ''
      this.defaultItem.description = ''
      this.editedItem = this.defaultItem
    },
  },
}
</script>
